import React, { createContext } from "react"
import { useInterpret } from "@xstate/react"
import { InterpreterFrom } from "xstate/lib/types"
import { referralMachine } from "../machines/referralMachine"
import { paymentMethodsMachine } from "../machines"

export const XStateContext = createContext({
  referralService: {} as InterpreterFrom<typeof referralMachine>,
  paymentMethodsService: {} as InterpreterFrom<typeof paymentMethodsMachine>
})

export const XStateProvider = (props) => {
  const referralService = useInterpret(referralMachine)
  const paymentMethodsService = useInterpret(paymentMethodsMachine)

  return (
    <XStateContext.Provider value={{ referralService, paymentMethodsService }}>
      {props.children}
    </XStateContext.Provider>
  )
}
