import { assign, createMachine } from "xstate"
import { addMonths } from "date-fns"
import { defaultMonthlyGivingAmount } from "../utils/helpers"

export interface LanguageContext {
  lang: string
}

export const languageMachine = createMachine(
  {
    id: "languageMachine",
    initial: "loading",
    context: {
      lang: "en-US"
    },
    states: {
      loading: {
        on: {
          CHANGE: {
            target: "loaded",
            actions: ["onChange"]
          }
        }
      },
      loaded: {}
    }
  },
  {
    actions: {
      onChange: assign({
        lang: (_ctx, e) => {
          // @ts-ignore
          return e.value
        }
      })
    }
  }
)

function convertAmount(amount: number | string | null) {
  if (amount === null) {
    return 0
  } else if (typeof(amount) === "number") {
    return amount
  } else {
    return Number(amount.replace("$", ""))
  }
}

export interface DonationAmountContext {
  values: {
    amount: null | string
    amountSelected: null | string
    chosenAmount: number  // the amount entered into either of the above fields, as a number; 0 if nothing is entered
    donationFeesCovered: boolean
  }
  errors: null | Array<string>
}

const initialDonationContext : DonationAmountContext = {
  values: {
    amount: null,
    amountSelected: null,
    chosenAmount: 0,
    donationFeesCovered: false,
  },
  errors: null
}
export const donationAmountMachine = createMachine(
  {
    id: "donationAmountMachine",
    initial: "editing",
    context:initialDonationContext,
    states: {
      editing: {
        on: {
          CHANGE: {
            target: "",
            actions: ["onChange"]
          },
          SUBMIT: "submitting",
          RESET: "resetting",
          TOGGLE_FEES_COVERED: {
            actions: assign({
              values: (context: DonationAmountContext) => {
                return {
                  ...context.values,
                  donationFeesCovered: !context.values.donationFeesCovered
                }
              }
            })
          }
        }
      },
      submitting: {
        invoke: {
          src: "onSubmit",
          onDone: "success",
          onError: {
            target: "error",
            actions: "onError"
          }
        }
      },
      resetting: {
        entry: assign(initialDonationContext),
        always: "editing"
      },
      success: {},
      error: {}
    }
  },
  {
    actions: {
      onChange: assign({
        values: (ctx, e) => {
          // @ts-ignore
          const {key, value} = e
          let newValues = {}
          if (key === "amount") {
            newValues = {
              amount: value,
              amountSelected: null,
              chosenAmount: convertAmount(value),
            }
          } else if (key === "amountSelected") {
            newValues = {
              amount: null,
              amountSelected: value,
              chosenAmount: convertAmount(value),
            }
          } else {
            newValues = {
              [key]: value
            }
          }
          return {
            ...ctx.values,
            ...newValues
          }
        }
      }),
      onError: assign({
        errors: (ctx, e) => ({
          ...ctx.errors,
        })
      })
    }
  }
)

export interface RecurringContext {
  values: {
    amount: string
    amountSelected: null | string
    chosenAmount: number  // the amount entered into either of the above fields, as a number; 0 if nothing is entered
    ministryPartnerChecked: boolean
    recurring: boolean
    recurOnDay: string
    recurStartDate: Date
  }
  errors: null | Array<string>
}

const initalRecurringContext : RecurringContext = {
  values: {
    amount: null,
    amountSelected: null,
    chosenAmount: 0,
    ministryPartnerChecked: false,
    recurring: false,
    recurOnDay: "1",
    recurStartDate: recurStartDate("1")
  },
  errors: null
} 

function recurStartDate(recurOnDay: string) {
  const recurDay = parseInt(recurOnDay)
  const today = new Date()
  // Start with recurDate on the specified day of next month
  let recurDate = new Date(today.getFullYear(), today.getMonth(), recurDay)
  recurDate = addMonths(recurDate, 1)
  // If recurDate is less than minimumDays from today, move it to the following month
  const minimumDays = 15
  if ((recurDate.getTime() - today.getTime())/(1000 * 60 * 60 * 24) < minimumDays) {
    recurDate = addMonths(recurDate, 1)
  }
  return recurDate
}

export const recurringMachine = createMachine(
  {
    id: "recurringMachine",
    initial: "editing",
    context: initalRecurringContext,
    states: {
      editing: {
        on: {
          CHANGE: {
            target: "",
            actions: ["onChange"]
          },
          SUBMIT: "submitting",
          RESET: "resetting"
        }
      },
      submitting: {
        invoke: {
          src: "onSubmit",
          onDone: "success",
          onError: {
            target: "error",
            actions: "onError"
          }
        }
      },
      resetting: {
        entry: assign(initalRecurringContext),
        always: "editing"
      },
      error: {},
      success: {}
    }
  },
  {
    actions: {
      onChange: assign({
        values: (ctx, e) => {
          // @ts-ignore
          const {key, value} = e
          const newStartDate = (key === "recurOnDay") ? {recurStartDate: recurStartDate(value)} : {}
          let newValues = {}
          if (key === "amount") {
            newValues = {
              amount: value,
              amountSelected: null,
              chosenAmount: convertAmount(value),
            }
          } else if (key === "amountSelected") {
            newValues = {
              amount: null,
              amountSelected: value,
              chosenAmount: convertAmount(value),
            }
          } else {
            newValues = {
              [key]: value
            }
          }
          return {
            ...ctx.values,
            ...newStartDate,
            ...newValues,
          }
        }
      }),
      onError: assign({
        errors: (ctx, e) => ({
          ...ctx.errors,
          // @ts-ignore
          [e.key]: e.errorText
        })
      })
    }
  }
)

export interface ProductCodesContext {
  product_codes: string[],
  selectedProduct: any
}

export const productCodesMachine = createMachine(
  {
    id: "productCodesMachine",
    initial: "editing",
    context: {
      product_codes: [],
      selectedProduct: null,
    },
    states: {
      editing: {
        on: {
          CHANGE: {
            target: "",
            actions: ["onChange"]
          }
        }
      }
    }
  },
  {
    actions: {
      onChange: assign({
        selectedProduct: (ctx, event) => {
          // @ts-ignore
          return event.selectedProduct
        },
        product_codes: (ctx, event) => {
          // @ts-ignore
          const { multiselect = false, value } = event

          if (multiselect) {
            const isCurrentlySelected = ctx.product_codes.find(
              (code) => code === value
            )

            if (isCurrentlySelected) {
              return ctx.product_codes.filter((code) => code !== value)
            } else {
              return ctx.product_codes.concat(value)
            }
          } else {
            return [value]
          }
        }
      })
    }
  }
)

export const paymentMethodsMachine = createMachine(
  {
    id: "paymentMethodsMachine",
    initial: "editing",
    context: {
      userPaymentMethods: null,
      selectedPaymentMethod: null
    },
    states: {
      editing: {
        on: {
          SET_SELECTED_PAYMENT_METHOD: {
            target: "",
            actions: ["setSelectedPaymentMethod"]
          },
          SET_USER_PAYMENT_METHODS: {
            target: "",
            actions: ["setUserPaymentMethods"]
          }
        }
      }
    }
  },
  {
    actions: {
      setUserPaymentMethods: assign({
        userPaymentMethods: (_ctx, event) => {
          // @ts-ignore
          return event.data
        }
      }),
      setSelectedPaymentMethod: assign({
        selectedPaymentMethod: (_ctx, event) => {
          // @ts-ignore
          return event.data
        }
      })
    }
  }
)
