import axios from "axios"
import { assign, createMachine } from "xstate"

export type Status = "idle" | "loading" | "ready" | "success" | "error"

export type Station = { description: string }

export interface Context {
  status: Status
  stations: Array<Station>
  currentOption: "My Local Radio Station" | "Other" | null
  value: string
}

export type Events =
  | { type: "OPTION_RADIO"; data: { zip: string } }
  | { type: "OPTION_OTHER" }
  | { type: "UPDATE_VALUE"; data: string }
  | { type: "SUBMIT_REFERRAL"; data: { campaignId: string; referral: string } }
  | {
      type: "done.invoke.fetchRadioStationsInvoke"
      data: Array<Station>
    }

export const referralMachine = createMachine(
  {
    tsTypes: {} as import("./referralMachine.typegen").Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events
    },
    id: "referralMachine",
    initial: "idle",
    context: {
      status: "idle",
      currentOption: null,
      stations: [],
      value: ""
    },
    states: {
      idle: {
        on: {
          OPTION_RADIO: {
            target: "radioSelected",
            actions: ["optionRadio", "clearValue"]
          },
          OPTION_OTHER: {
            actions: ["optionOther", "clearValue"]
          },
          UPDATE_VALUE: {
            actions: "updateValue"
          },
          SUBMIT_REFERRAL: {
            target: "referralSumbitted"
          }
        }
      },
      radioSelected: {
        entry: "setStatusLoading",
        invoke: {
          id: "fetchRadioStationsInvoke",
          src: "fetchRadioStations",
          onError: {
            target: "idle",
            actions: "noRadioStationsFound"
          },
          onDone: {
            target: "idle",
            actions: "saveRadioStations"
          }
        }
      },
      referralSumbitted: {
        invoke: {
          id: "submitReferralInvoke",
          src: "submitReferral",
          onDone: {
            target: "idle",
            actions: "setStatusSuccess"
          },
          // To do: handle errors properly by displaying something to the user
          // to let them know something went wrong.
          onError: {
            target: "idle",
            actions: "setStatusError"
          }
        }
      }
    }
  },
  {
    services: {
      submitReferral: async (_ctx, event) => {
        const { data: { campaignId, referral } = {} } = event

        if (!campaignId || !referral) {
          throw new Error("Missing campaignId or referral")
        }

        const { data } = await axios({
          url: `/api/${campaignId}/transactions`,
          method: "PUT",
          data: { referral }
        })

        if (!data.success) {
          throw new Error("Something Went Wrong")
        }

        return data
      },
      fetchRadioStations: async (_ctx, event) => {
        const postalCode = event.data?.zip

        if (!postalCode) {
          throw new Error("No postal code provided")
        }

        const { data } = await axios.get(`/api/lookupStations/${postalCode}`)

        return data.stations
      }
    },
    actions: {
      clearValue: assign({ value: "" }),
      updateValue: assign({ value: (_ctx, event) => event.data }),
      optionRadio: assign({ currentOption: "My Local Radio Station" }),
      optionOther: assign({ currentOption: "Other" }),
      setStatusLoading: assign({ status: "loading" }),
      setStatusSuccess: assign({ status: "success" }),
      setStatusError: assign({ status: "error" }),
      saveRadioStations: assign((_ctx, event) => {
        const { data } = event

        return {
          stations: data,
          status: "ready" as Status
        }
      }),
      noRadioStationsFound: assign({ status: "ready" })
    }
  }
)
