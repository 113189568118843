import { ReactElement } from "react"
import Script from "next/script"
import * as snippet from "@segment/snippet"

function renderSnippet(apiKey) {
  const opts = {
    apiKey,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true
  }

  if (process.env.NEXT_PUBLIC_ENV !== "production") {
    return snippet.max(opts)
  }

  return snippet.min(opts)
}

export const SegmentAnalytics = ({
  apiKey,
  children
}: {
  apiKey: string
  children: ReactElement
}) => {
  return (
    <>
      {/* Inject the Segment snippet into the <head> of the document  */}
      <Script
        id="segment-script"
        dangerouslySetInnerHTML={{ __html: renderSnippet(apiKey) }}
      />
      {children}
    </>
  )
}
