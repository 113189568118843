// contexts/trackers.jsx
import React, { useEffect, useState } from "react"
import Router from "next/router"
import ReactGA from "react-ga"
import { Campaign } from "../types"

const GoogleAnalyticsTrackingID = process.env.NEXT_PUBLIC_GA_TRACKING_ID

const TrackingContext = React.createContext(null)

declare global {
  interface Window {
    google_conversion_id: string
    google_conversion_language: string
    google_conversion_format: string
    google_conversion_color: string
    google_conversion_label: string
    google_conversion_value: string
    google_conversion_currency: string
    google_remarketing_only: boolean
    analytics: any
  }
}

function TrackingProvider(props) {
  const [analytics, setAnalytics] = useState({
    isInitialized: false,
    trackers: []
  })
  const [isFormStartedEventTracked, setIsFormStartedEventTracked] =
    useState(false)
  const [
    isConfirmationLoadedEventTracked,
    setIsConfirmationLoadedEventTracked
  ] = useState(false)

  const identify = ({
    firstName,
    lastName,
    email,
    phone,
    city,
    state,
    country,
    postalCode
  }) => {
    window.analytics &&
      window.analytics.identify({
        firstName,
        lastName,
        email,
        phone,
        city,
        state,
        country,
        postalCode
      })
  }

  const trackConfirmationLoadedEvent = ({ campaign, transaction }) => {
    if (!isConfirmationLoadedEventTracked) {
      const isFree = campaign && campaign.offer && campaign.offer.free
      if (isFree) {
        // It's a FREE offer campaign
        identify({
          firstName: transaction.first_name,
          lastName: transaction.last_name,
          email: transaction.email,
          phone: transaction.phone,
          city: transaction.city,
          state: transaction.state,
          country: transaction.country,
          postalCode: transaction.postal_code
        })
        global.analytics.track("FreeOfferCompleted", {
          type: "Free Offer",
          id: transaction.ds_transaction_id,
          amount: transaction.amount,
          productCodes: transaction.product_codes,
          sourceCode: campaign.source_code,
          firstName: transaction.first_name,
          lastName: transaction.last_name,
          email: transaction.email,
          phone: transaction.phone,
          city: transaction.city,
          state: transaction.state,
          country: transaction.country,
          postalCode: transaction.postal_code,
          recurring: transaction.recurring,
          ministryPartner: transaction.user.ministry_partner
        })
      } else {
        // It's either a Donation or a GOAA
        const productCodes = transaction.product_codes
        const isDonation = !productCodes.length
        const eventName = isDonation ? "DonationCompleted" : "GOAACompleted"
        const type = isDonation ? "Donation" : "GOAA"

        identify({
          firstName: transaction.first_name,
          lastName: transaction.last_name,
          email: transaction.email,
          phone: transaction.phone,
          city: transaction.city,
          state: transaction.state,
          country: transaction.country,
          postalCode: transaction.postal_code
        })
        global.analytics.track(eventName, {
          type,
          id: transaction.ds_transaction_id,
          amount: transaction.amount,
          productCodes: transaction.product_codes,
          sourceCode: campaign.source_code,
          firstName: transaction.first_name,
          lastName: transaction.last_name,
          email: transaction.email,
          phone: transaction.phone,
          city: transaction.city,
          state: transaction.state,
          country: transaction.country,
          postalCode: transaction.postal_code,
          recurring: transaction.recurring,
          ministryPartner: transaction.user.ministry_partner
        })
      }

      setIsConfirmationLoadedEventTracked(true)
    }
  }

  const trackFormStartedEvent = (campaign: Campaign) => {
    if (!isFormStartedEventTracked) {
      switch (campaign && campaign.offer && campaign.offer.free) {
        case true: //free
          global.analytics.track("FreeOfferStarted", {
            type: "Free Offer"
          })
          break
        case false: //goaa
          global.analytics.track("GOAAStarted", {
            type: "GOAA"
          })
          break
        case null: //donation
          global.analytics.track("DonationStarted", {
            type: "Donation"
          })
          break
        default:
          break
      }

      setIsFormStartedEventTracked(true)
    }
  }

  // Since this is an SPA we listen to the react-router
  // route change event and manually fire our page view events
  const handleRouteChange = (url) => {
    // Google Analytics tracking
    ReactGA.set({ page: url }, analytics.trackers)
    ReactGA.pageview(url, analytics.trackers)

    // Segment tracking
    window.analytics.page(url)
  }

  // We'll define our logEvent function before useEffect
  const logEvent = ({ category = "", action = "", label = "" }) => {
    if (analytics.isInitialized) {
      ReactGA.event(
        {
          category,
          action,
          label
        },
        analytics.trackers
      )
    }
  }

  const setUpGoogleAdServices = () => {
    window.google_conversion_id = process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_ID
    window.google_conversion_language =
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_LANGUAGE
    window.google_conversion_format =
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_FORMAT
    window.google_conversion_color =
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_COLOR
    window.google_conversion_label =
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_LABEL
    window.google_conversion_value =
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_VALUE
    window.google_remarketing_only = false

    // this is the only optional value (RBC does not have this)
    if (process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_CURRENCY) {
      window.google_conversion_currency =
        process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_CURRENCY
    }

    const script = document.createElement("script")
    const noscript = document.createElement("noscript")
    const div = document.createElement("div")
    const img = document.createElement("img")

    script.src = "//www.googleadservices.com/pagead/conversion.js"
    script.type = "text/javscript"

    img.height = 1
    img.width = 1
    img.style.borderStyle = "none"
    img.src = `//www.googleadservices.com/pagead/conversion/${
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_ID
    }/?${
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_CURRENCY &&
      "value=1.00&amp;currency_code=USD&amp;"
    }label=39u-COz2qGEQyPnK_gM&amp;guid=ON&amp;script=0`
    img.alt = ""

    div.style.display = "inline"

    div.appendChild(img)
    noscript.appendChild(div)
    document.body.appendChild(script)
    document.body.appendChild(noscript)

    // The above code appends this html to the DOM
    // document.body.appendChild(
    //   <>
    //     <script
    //       type="text/javascript"
    //       src="//www.googleadservices.com/pagead/conversion.js"
    //     ></script>
    //     <noscript>
    //       <div style="display:inline;">
    //         <img
    //           height="1"
    //           width="1"
    //           style="border-style:none;"
    //           alt=""
    //           src="//www.googleadservices.com/pagead/conversion/1070775496/?value=1.00&amp;currency_code=USD&amp;label=39u-COz2qGEQyPnK_gM&amp;guid=ON&amp;script=0"
    //         />
    //       </div>
    //     </noscript>
    //   </>,
    // );
  }

  useEffect(() => {
    const { isInitialized } = analytics

    // How you detect which user is currently logged in
    // depends on the way you've set up authentication within
    // your app, the important thing is getting the userId

    // initialize GA with our tracking id
    // uncomment the user tracking method that works for you

    if (!isInitialized) {
      ReactGA.initialize(GoogleAnalyticsTrackingID, { debug: true })
      ReactGA.ga("send", "pageview")
      // Handle all route changes
      ReactGA.ga((tracker) => {
        setAnalytics((prev) => ({ ...prev, trackers: [tracker] }))
      })

      Router.events.on("routeChangeComplete", handleRouteChange)

      setAnalytics((prev) => ({
        ...prev,
        isInitialized: true
      }))
    }

    return () => {
      // clean up
      Router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [analytics])

  return (
    <TrackingContext.Provider
      value={{
        logEvent,
        ga: ReactGA.ga,
        trackFormStartedEvent,
        isFormStartedEventTracked,
        trackConfirmationLoadedEvent,
        isConfirmationLoadedEventTracked,
        setUpGoogleAdServices
      }}
      {...props}
    />
  )
}

const useTracking = () => React.useContext(TrackingContext)

export { TrackingProvider, useTracking }
